import React from "react";
import { connect } from "react-redux";
import { UserManager } from "oidc-client";
import { setUser } from "actions";

const dispatchAction = (dispatch, action) => dispatch(action);

class CallbackPage extends React.Component {
  componentDidMount() {
    new UserManager({ response_mode: "query" })
      .signinRedirectCallback()
      .then((user) => {
        dispatchAction(this.props.dispatch, setUser(user));
        window.appInsights.setAuthenticatedUserContext(user.profile.sub);
        this.props.history.push("/messages");
      })
      .catch(function (e) {
        console.error("callback error", e);
        // window.location = "/";
      });
  }

  render() {
    return <div></div>;
  }
}

CallbackPage = connect()(CallbackPage);
export default CallbackPage;
