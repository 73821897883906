import React from 'react'
import Translation from 'components/Translation'

const Banking = () => {
    return (
        <section className="pt-5">
            <div className="container text-center">
                <h3><Translation translate='Banking_Messages' textonly="true" /></h3>
                <p><Translation translate='To_bank_messages' textonly="true" /></p>
            </div>
        </section>
    )
}

export default Banking