import React from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { MessageBank } from "messagebanking-module";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Messages extends React.Component {
  render() {
    if (this.props.login) {
      return (
        <div>
          <Navbar />
          <div className="container-fluid">
            <div className="row message-row">
              <div className="col-xs-12 col-lg-push-1 col-lg-10">
                <MessageBank
                  access_token={this.props.login.access_token}
                  useHash={false}
                  client="mmb"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

const mapStateToProps = (state) => ({
  login: state.app.getIn(["user"]),
});

Messages = connect(mapStateToProps)(Messages);

export default Messages;
