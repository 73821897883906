import React, { Component } from 'react'

import Translation from 'components/Translation'

class Footer extends Component {

    

    render() {
        const currentYear = (new Date()).getFullYear()
        return (
            <footer className="mt-5 py-3">
                <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-xs-1 visible-xs-inline-block"></div>
                        <div className="col-xs-5 col-sm-6 text-right">
                            <a href="http://www.childrenshospital.org/centers-and-services/departments-and-divisions/department-of-otolaryngology-and-communication-enhancement" className="branding" target="_blank" rel="noopener noreferrer"><img src="img/logo-bch-color.svg" className="" alt="Boston Children's Hospital Logo" /></a>
                        </div>
                        <div className="col-xs-5 col-sm-6 text-left">
                            <a href="http://www.tobiidynavox.com" className="branding" target="_blank" rel="noopener noreferrer"><img src="img/logo-td-color.svg" className="" alt="Tobii Dynavox Logo" /></a>
                        </div>
                    </div>
                    <p className="copyright pt-3">
                        <small>
                            <a href="https://www.tobiidynavox.com/pages/privacy-policy" target="_blank" rel="noopener noreferrer"><Translation translate='Privacy_Policy' /></a><a href="#" onClick={(e) => e.preventDefault()} data-toggle="modal" data-target="#termsAndConditions" className="text-link"><Translation translate='Terms_and_Conditions' /></a><br />&copy;{currentYear} - <Translation translate='TD_partnership_BCH' textonly="true" />
                        </small>
                    </p>
                </div>
            </footer>
        )

    }
}

export default Footer



