import React from "react";
import { getTranslations, setUser, setAccessToken } from "actions";
import userManager from "utils/userManager";
import MmbRoutes from "routes";
import "css/site.css";

const dispatchAction = (dispatch, action) => dispatch(action);
class App extends React.Component {
  componentDidMount() {
    dispatchAction(this.props.store.dispatch, getTranslations());

    //Check session storage for a logged in user and dispatch a redux event if found.
    userManager
      .getUser()
      .then((user) => {
        if (user) {
          dispatchAction(this.props.store.dispatch, setUser(user));
          window.appInsights.setAuthenticatedUserContext(user.profile.sub);
        }
      })
      .catch(function (e) {
        console.error("callback error", e);
      });

    userManager.events.addAccessTokenExpired(async () => {
      const timeElapsed = Date.now();
      const now = new Date(timeElapsed);
      await this.refreshToken();
    });

    let lock = false;

    userManager.events.addAccessTokenExpiring(async () => {
      if (lock === false) {
        lock = true;
        const timeElapsed = Date.now();
        const now = new Date(timeElapsed);
        await this.refreshToken();
        lock = false;
      }
    });
  }

  async refreshToken() {
    var user = await userManager.getUser();
    var body = `client_id=${userManager.settings.client_id}&grant_type=refresh_token&refresh_token=${user.refresh_token}`;
    var authorityUrl = process.env.REACT_APP_SSO_AUTHORITY;

    var response = await fetch(authorityUrl + "/connect/token", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: body,
    });

    var data = await response.json();

    if (!data.error) {
      user.id_token = data.id_token;
      user.access_token = data.access_token;
      user.refresh_token = data.refresh_token;
      user.expires_in = data.expires_in;
      userManager.storeUser(user);

      dispatchAction(
        this.props.store.dispatch,
        setAccessToken(data.access_token)
      );
    } else userManager.clearStaleState();
  }

  render() {
    const { store } = this.props;
    return (
      <div>
        <MmbRoutes store={store} />
      </div>
    );
  }
}

export default App;
