import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "components/Home";
import Login from "components/Login";
import Logout from "components/Logout";
import About from "components/About";
import CallbackPage from "components/CallbackPage";
import Messages from "components/Messages";
import Print from "components/PrintMessages";

class MmbRoutes extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/login" exact component={Login} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/callback" exact component={CallbackPage} />
          <Route
            path="/print-messages"
            exact
            render={() => {
              return <Print />;
            }}
          />
          <Route
            path="/messages"
            render={() => {
              return <Messages />;
            }}
          />
        </Switch>
      </Router>
    );
  }
}

export default MmbRoutes;
