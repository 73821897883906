import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "components/App";
import { store } from "store";

window.MESSAGE_BANK_API_URL = process.env.REACT_APP_MESSAGE_BANK_API_URL;

ReactDOM.render(
  <Provider store={store}>
    <App store={store} />
  </Provider>,
  document.getElementById("root")
);
