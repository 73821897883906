import React from 'react'
import Translation from 'components/Translation'

const How = () => {
    return (
        <section className="py-5">
            <div className="container text-center">
                <h3><Translation translate='How_It_Works' textonly="true" /></h3>
                <p><Translation translate='How_it_works_txt' textonly="true" /></p>
            </div>
        </section>
    )
}

export default How