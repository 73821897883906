import axios from "axios";
import * as at from "actionTypes";
import { dynamicSort } from "resources/helpers";
import userManager from "utils/userManager";

let mytdURL = process.env.REACT_APP_MYTD_URL;

let langCookie = () =>
  !!window.getCookieValue("locale")
    ? window.getCookieValue("locale")
    : !!window.localeCookieValue
    ? window.localeCookieValue
    : "en-US";

async function getUserAsync() {
  var user = await userManager.getUser();
  return user;
}

async function _baseMyTDCall() {
  return axios.create({
    baseURL: mytdURL + "/api/",
    timeout: 1200000,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "*/*",
      "Accept-Language": langCookie(),
    },
  });
}

export const APIService = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 1200000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "*/*",
    "Accept-Language": langCookie(),
    "Access-Control-Allow-Origin": "*",
    Vary: "Origin",
  },
});

async function mbService() {
  var res = await getUserAsync();
  const authString = res.access_token ? `Bearer ${res.access_token}` : "";
  let mbServicesURL = "";
  if (typeof window.MESSAGE_BANK_API_URL !== "undefined") {
    mbServicesURL = window.MESSAGE_BANK_API_URL + "/api/messagebank";
  }
  return axios.create({
    baseURL: mbServicesURL,
    timeout: 1200000,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "*/*",
      "Accept-Language": langCookie(),
      Authorization: authString,
    },
  });
}

// const getTranslations = () => {
//     return APIService.get(`resources`)
// }

export const getTranslations = async () => {
  const base = await _baseMyTDCall();
  let data = await base.get("/resources");
  let thisItem = await data.data;
  return thisItem;
};

export const getAvailLangs = async () => {
  const base = await mbService();
  let data = await base.get(`/languagemodels`);
  let allAvailLangs = await data.data._embedded.languageModels;
  let azureLangs = [];
  allAvailLangs.forEach((el) => {
    if (el.transcriptionEngine === "azure") {
      azureLangs.push(el);
    }
  });
  azureLangs.sort(dynamicSort("name", 1));
  return azureLangs;
};

const APIMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case at.GET_TRANSLATIONS:
      getTranslations().then((res) =>
        next({ type: at.GET_TRANSLATIONS_OK, translations: res })
      );
      break;
    case at.GET_AVAILABLE_LANGS:
      getAvailLangs().then((res) =>
        next({ type: at.GET_AVAILABLE_LANGS_RECEIVED, availLangs: res })
      );
      break;
    default:
      break;
  }
  return next(action);
};

export default APIMiddleware;
