import React from 'react'
import { connect } from 'react-redux'

class Translation extends React.Component {

    render() {
        let { pre, translation, post, textonly } = this.props

        pre = pre ? pre : ""
        post = post ? post : ""

        let thisContent = null

        if (textonly === 'true') {
            thisContent = translation ? translation : null
        }

        else {
            thisContent = translation ? <span dangerouslySetInnerHTML={{__html: pre + ' ' + translation + ' ' + post}} />: null
        }

        return thisContent
        
    }
}

const mapStateToProps = (state, props) => (
    {
        translation: state.app.getIn(['translations', props.translate])
    }
)

export default connect(mapStateToProps)(Translation)