export const SiteLanguages = [
  {
    name: "Chinese",
    label: "中文",
    //label: '中文(简体)', - matching SSO
    code: "zh",
  },
  {
    name: "Danish",
    label: "dansk",
    code: "da",
  },
  {
    name: "German",
    label: "Deutsch",
    code: "de",
  },
  {
    name: "English",
    label: "English",
    code: "en",
  },
  {
    name: "Spanish",
    label: "español",
    code: "es",
  },
  {
    name: "French",
    label: "français",
    code: "fr",
  },
  {
    name: "Italian",
    label: "italiano",
    code: "it",
  },
  {
    name: "Dutch",
    label: "Nederlands",
    code: "nl",
  },
  {
    name: "Norwegian",
    label: "norsk bokmål",
    code: "nb",
  },
  {
    name: "Portuguese",
    label: "português",
    code: "pt",
  },
  {
    name: "Swedish",
    label: "svenska",
    code: "sv",
  },
]

// Íslensku

export const checkSiteLangSupport = (code) => {
  let isSupported = (lang) => code === lang.code
  return SiteLanguages.some(isSupported)
}

export const fixLocaleCode = (code) => {
  if (code === "nn" || code === "no") {
    code = "nb"
  }
  let mainCode = code.includes("-") ? code.split("-")[0] : code
  return mainCode
}
