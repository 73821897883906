import { UserManager } from "oidc-client";

const config = {
  client_id: "mymb-pkce",
  redirect_uri: `${window.location.origin}/callback`,
  response_type: "code",
  scope: "openid profile offline_access message-bank-services",
  authority: process.env.REACT_APP_SSO_AUTHORITY,
  silent_redirect_uri: `${window.location.origin}/silentrenew`,
  automaticSilentRenew: false,
  post_logout_redirect_uri: `${window.location.origin}`,
  // filterProtocolClaims: true,
  //loadUserInfo: true
};

var userManager = new UserManager(config);

export default userManager;
