import React from "react"
import Translation from "components/Translation"

const Additional = () => {
  return (
    <section className="additional-info-wrapper mt-4">
      <div className="container-fluid text-center">
        <div className="row">
          <div className="col-sm-8 col-sm-push-2 divider pt-5">
            <p>
              <Translation translate="For_additional_info" textonly="true" />
            </p>
            <p className="pt-4">
              <small>
                <a
                  href="https://download.mytobiidynavox.com/Message%20Banking/message-bank-getting-started_en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Translation translate="Getting_started_mb" textonly="true" />
                </a>{" "}
                (<Translation translate="Also_Available_In" textonly="true" />
                <span className="no-pad">
                  <a href="https://download.mytobiidynavox.com/Message%20Banking/tobiidynavox-mit_messagebanking_arbeiten_1.1.pdf" target="_blank" rel="noopener noreferrer" className="sm-pad"><Translation translate="German" textonly="true" /></a>,{" "}<a href="https://download.mytobiidynavox.com/Message%20Banking/TobiiDynavox_GettingStarted_withMessageBanking_v1-0_nb-NO_WEB.pdf" target="_blank" rel="noopener noreferrer" className="sm-pad"><Translation translate="Norwegian" textonly="true" /></a>
                </span>
                )
                <br className="visible-xs-block" />
                <a
                  href="https://download.mytobiidynavox.com/Message%20Banking/message-bank-faq.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Translation
                    translate="Message_Banking_FAQs"
                    textonly="true"
                  />
                </a>{" "}
                (
                <Translation
                  translate="Available_in_English_Only"
                  textonly="true"
                />
                )
              </small>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Additional
