import React from 'react'
import Translation from 'components/Translation'

const Welcome = () => {
    return (
        <section className="intro-wrapper py-5">
            <div className="container text-center">
                <h2><Translation translate='Message_banking_everyone' textonly="true" /></h2>
                <h4 id="welcome-text"><Translation translate='MB_intro_1' /></h4>
            </div>
        </section>
    )
}

export default Welcome