import * as at from "actionTypes";
import { Map, fromJS } from "immutable";
import userManager from "utils/userManager";

const app = (
  state = Map({
    pageSize: 10,
  }),
  action
) => {
  switch (action.type) {
    case at.GET_TRANSLATIONS_OK:
      return state.set("translations", fromJS(action.translations));
    case at.GET_AVAILABLE_LANGS_RECEIVED:
      return state.set("availLangs", action.availLangs);
    case at.SET_USER:
      return state.setIn(["user"], action.user);
    default:
      return state;
  }
};

export default app;
