import React from "react";
import Footer from "components/Footer";
import Hero from "components/blocks/Hero";
import Welcome from "components/blocks/Welcome";
import How from "components/blocks/How";
import Costello from "components/blocks/Costello";
import Banking from "components/blocks/Banking";
import Process from "components/blocks/Process";
import Additional from "components/blocks/Additional";
import Started from "components/blocks/Started";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Home extends React.Component {
  render() {
    if (!this.props.login) {
      return (
        <div className="fade-block fade-in">
          <Hero />
          <Welcome />
          <How />
          <Costello />
          <Banking />
          <Process />
          <Started />
          <Additional />
          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/messages" />;
    }
  }
}

const mapStateToProps = (state) => ({
  login: state.app.getIn(["user"]),
});

Home = connect(mapStateToProps)(Home);
export default Home;
