import { combineReducers } from "redux";

import app from "./App";
import { MessageBankReducers as messagebank } from "messagebanking-module";

const reducers = combineReducers({
  messagebank,
  app,
});

export default reducers;
