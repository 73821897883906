import React from 'react'

class Logout  extends React.Component {
  
  componentDidMount() {
  }

  render() {
    return (
      <div>logging out...</div>
    )
  }
}

export default Logout
