import React from 'react'
import userManager from 'utils/userManager'
import Translation from 'components/Translation'
import closeIcon from 'img/ico-nav-close.svg'
import LangSelector from 'components/LangSelector'

class Hero extends React.Component {

    
    onLoginButtonClick(event) {
        event.preventDefault();
        userManager.signinRedirect()
    }


    componentDidMount() {
        window.$(document).trigger("enhance");
        window.$('[data-offcanvas-trigger="menu"]').on("click", function(e) {
            e.stopImmediatePropagation()
            // $(".offcanvas-collapse").toggleClass("open")
            // if ($(".offcanvas-collapse").hasClass("open")) {
            //     document.addEventListener("click", triggerOffCanvasMenu)
            // } else {
            //     document.removeEventListener("click", triggerOffCanvasMenu)
            // }
            window.$('js-offcanvas-trigger').blur()
        })
    }

    render () {

        return (
            <section className="hero-wrapper">
                <div className="top-wrapper container-fluid">
                    <div className="row">
                        <div className="hero-logo col-xs-7 col-sm-5 col-lg-push-2 col-lg-4">
                            <a href="/"><img src="img/logo-mymb-white.svg" className="logo" alt="myMessageBanking" title="myMessageBanking" /></a>
                        </div>
                        <div id="hero-menu-toggle" className="col-xs-5 col-sm-7 col-lg-push-2 col-lg-4 text-right">
                            <button className="hidden-xs btn btn-default btn-menu-fixed" onClick={(e) => this.onLoginButtonClick(e)}><Translation translate='Log_in' /></button>
                            <button 
                                type="button" 
                                className="btn btn-primary btn-menu-pinned btn-primary-outline btn-icon js-offcanvas-trigger" 
                                data-offcanvas-trigger="menu"
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </span>
                            </button>

                            <aside 
                                id="menu" 
                                className="js-offcanvas js-open" 
                                data-offcanvas-options='{"modifiers":"right,overlay"}' 
                                role="complementary"
                            >
                                <a 
                                    href="#" 
                                    onClick={(e) => e.preventDefault()}
                                    className="closebtn js-offcanvas-close" 
                                    data-button-options='{"modifiers":"hard,close-right"}'
                                >
                                    <img src={closeIcon} className="nav-icon text-right" alt="Close" title="Close" />
                                </a>
                                <div className="menu-links">
                                    <LangSelector/>
                                </div>
                            </aside>

                        </div>
                        
                    </div>
                </div>
                <div className="hero-content container-fluid">
                    <div className="row">
                        <div className="col-sm-6 col-sm-push-6">
                            <h1><Translation translate='Preserve_your_voice' textonly="true" /></h1>
                            <h2><Translation translate='Maintain_connection' textonly="true" /></h2>
                            <a href="#" className="visible-xs-block btn btn-default" onClick={(e) => this.onLoginButtonClick(e)}><Translation translate='Log_in' /></a>
                            <a href={`${userManager.settings.authority}/account/register?returnUrl=${window.location.origin}/login`} className="btn btn-default"><Translation translate='Open_free_account' textonly="true" /></a>
                        </div>
                    </div>
                </div>
                <div className="scroll-icon text-center">
                    <img src="img/ico-scroll-arrow.svg" className="nav-icon bounce" alt="" title="" />
                </div>
            </section>
        )
    }
}

export default Hero