export const shortLocaleLabel = (code) => {
  let labelOutput = ""
  switch (code) {
    case "da":
      labelOutput = "DA"
      break
    case "de":
      labelOutput = "DE"
      break
    case "en-US":
      labelOutput = "EN"
      break
    case "es":
      labelOutput = "ES"
      break
    case "fr":
      labelOutput = "FR"
      break
    case "nl":
      labelOutput = "NL"
      break
    case "nb":
    case "nn":
    case "no":
      labelOutput = "NB"
      break
    case "pt":
      labelOutput = "PT"
      break
    case "sv":
      labelOutput = "SV"
      break
    case "zh":
      labelOutput = "ZH"
      break
    default:
      labelOutput = "EN"
      break
  }
  return labelOutput
}

export const fullLocaleLabel = (code) => {
  let labelOutput = ""
  switch (code) {
    case "da":
      labelOutput = "dansk"
      break
    case "de":
      labelOutput = "Deutsch"
      break
    case "en":
      labelOutput = "English"
      break
    case "es":
      labelOutput = "español"
      break
    case "fr":
      labelOutput = "français"
      break
    case "it":
      labelOutput = "italiano"
      break
    case "nl":
      labelOutput = "Nederlands"
      break
    case "nb":
    case "nn":
    case "no":
      labelOutput = "norsk bokmål"
      break
    case "pt":
      labelOutput = "português"
      break
    case "sv":
      labelOutput = "svenska"
      break
    case "zh":
      labelOutput = "中文"
      break
    default:
      labelOutput = "English"
      break
  }
  return labelOutput
}
