import React from 'react'
import userManager from 'utils/userManager'

class Login  extends React.Component {
  componentDidMount() {
    userManager.signinRedirect()
  }
  render() {
    return (
      <div></div>
    )
  }
}

export default Login