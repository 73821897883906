import { createStore, compose, applyMiddleware } from "redux";
import reducers from "reducers";
import APIMiddleware from "services/APIMiddleware";
import { MessageBankService } from "messagebanking-module";
import thunk from "redux-thunk";

const loggerMiddleware = (store) => (next) => (action) => {
  next(action);
};

const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk, APIMiddleware, MessageBankService, loggerMiddleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose
  )
);

export { store };
