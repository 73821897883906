import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import LangSelector from 'components/LangSelector'
import userManager from 'utils/userManager'
import Translation from 'components/Translation'
import colorLogo from 'img/logo-mymb-color.svg'
import closeIcon from 'img/ico-nav-close.svg'
import {MessageUpload, MessageDownload} from 'messagebanking-module'

class Navbar extends React.Component {

    constructor(props) {
        super(props)

        this.signOut = this.signOut.bind(this)

    }

    signOut = (e) => {
        e.preventDefault()
        userManager.signoutRedirect().then(() => {
            userManager.clearStaleState()
        })
    }
    
    componentDidMount() {
        window.$(document).trigger("enhance");
    }

    render() {
        return (
            <section>
                <div className="top-wrapper menu-wrapper container-fluid">
                    <div className="row">
                        <div className="col-xs-7 col-sm-5 col-lg-push-1 col-lg-5">
                            <a href="/"><img src={colorLogo} className="logo" alt="myMessageBanking" title="myMessageBanking" /></a>
                        </div>
                        <div id="hero-menu-toggle" className="col-xs-5 col-sm-7 col-lg-push-1 col-lg-5 text-right">
                            <span className="hidden-xs">
                                <MessageUpload />
                                <MessageDownload />
                            </span>
                            <button 
                                type="button" 
                                className="btn btn-primary btn-primary-outline btn-icon js-offcanvas-trigger" 
                                data-offcanvas-trigger="menu"
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </span>
                            </button>
                            <aside 
                                id="menu" 
                                className="js-offcanvas js-open" 
                                data-offcanvas-options='{"modifiers":"right,overlay"}' 
                                role="complementary"
                            >
                                <a 
                                    href="#" 
                                    onClick={(e) => e.preventDefault()}
                                    className="closebtn js-offcanvas-close" 
                                    data-button-options='{"modifiers":"hard,close-right"}'
                                >
                                    <img src={closeIcon} className="nav-icon text-right" alt="Close" title="Close" />
                                </a>

                                <div className="menu-links">
                                    <Link to="/messages"><Translation translate='MYTD_MBtabLblMyMessages' /></Link>
                                    <Link to="/about"><Translation translate='About_Us' /></Link>
                                    <a href={`${userManager.settings.authority}/manage/editprofile?returnUrl=${window.location.origin}`}><Translation translate='Edit_profile' /></a>
                                    <a href={`${userManager.settings.authority}/manage/changepassword?returnUrl=${window.location.origin}`}><Translation translate='Change_Password' /></a>
                                    <a href="#" onClick={(e) => this.signOut(e)}><Translation translate='Log_off' /></a>
                                    <LangSelector/>
                                </div>

                            </aside>
                        </div>
                    </div>
                </div>
                <div className="container-fluid hidden-sm hidden-md hidden-lg">
                    <div className="row">
                        <div className="col-xs-12 text-center pt-2">
                            <MessageUpload />
                            <MessageDownload />
                        </div>
                    </div>
                </div>
            </section>
            
        )
    }
}


const mapStateToProps = (state) => (
    {
        login: state.app.get('login')
    }
)

Navbar = connect(mapStateToProps)(Navbar)
export default Navbar