import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { PrintMessages } from "messagebanking-module";

class Print extends React.Component {
  render() {
    if (this.props.login) {
      const profile = this.props.login.profile,
        thisToken = this.props.login.access_token;

      return (
        <PrintMessages
          fullName={`${profile.given_name} ${profile.family_name}`}
          accessToken={thisToken}
          useHash={false}
        />
      );
    } else {
      return <Redirect to="/print-messages" />;
    }
  }
}

const mapStateToProps = (state) => ({
  login: state.app.getIn(["user"]),
});

Print = connect(mapStateToProps)(Print);

export default Print;
