import React from 'react'
import Translation from 'components/Translation'

class Process extends React.Component {

    componentDidMount() {
        setTimeout(()=> window.$('.process').matchHeight(),1000)
    }

    render() {
        return (
            <section className="pt-5 pb-4">
                <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-sm-8 col-sm-push-2">
                            <h5 className="pb-4"><Translation translate='The_Message_Banking_Process' textonly="true" /></h5>
                            <div className="row">
                                <div className="process col-sm-6 col-lg-4 pt-4">
                                    <div className="row">
                                        <div className="col-xs-2 col-sm-12">
                                            <img src="img/ico-record.svg" className="img-responsive center-block" alt="" title="" />
                                        </div>
                                        <div className="col-xs-10 col-sm-12">
                                            <p className="pt-4 text-left"><Translation translate='mb_process_1' textonly="true" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="process col-sm-6 col-lg-4 pt-4">
                                    <div className="row">
                                        <div className="col-xs-2 col-sm-12">
                                            <img src="img/ico-azure-cognitive-services.svg" className="img-responsive center-block" alt="" title="" />
                                        </div>
                                        <div className="col-xs-10 col-sm-12">
                                            <p className="pt-4 text-left"><Translation translate='mb_process_2_azure' textonly="true" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="process col-sm-6 col-lg-4 pt-4">
                                    <div className="row">
                                        <div className="col-xs-2 col-sm-12">
                                            <img src="img/ico-listen.svg" className="img-responsive center-block" alt="" title="" />
                                        </div>
                                        <div className="col-xs-10 col-sm-12">
                                            <p className="pt-4"><Translation translate='mb_process_3' textonly="true" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="process col-sm-6 col-lg-4 pt-4">
                                    <div className="row">
                                        <div className="col-xs-2 col-sm-12">
                                            <img src="img/ico-tag.svg" className="img-responsive center-block" alt="" title="" />
                                        </div>
                                        <div className="col-xs-10 col-sm-12">
                                            <p className="pt-4"><Translation translate='mb_process_4' textonly="true" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="process col-sm-6 col-lg-4 pt-4">
                                    <div className="row">
                                        <div className="col-xs-2 col-sm-12">
                                            <img src="img/ico-upload.svg" className="img-responsive center-block" alt="" title="" />
                                        </div>
                                        <div className="col-xs-10 col-sm-12">
                                            <p className="pt-4"><Translation translate='mb_process_5' textonly="true" /></p>
                                        </div>  
                                    </div>
                                </div>
                                <div className="process col-sm-6 col-lg-4 pt-4">
                                    <div className="row">
                                        <div className="col-xs-2 col-sm-12">
                                            <img src="img/ico-download.svg" className="img-responsive center-block" alt="" title="" />
                                        </div>
                                        <div className="col-xs-10 col-sm-12">
                                            <p className="pt-4"><Translation translate='mb_process_6' textonly="true" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    
}

export default Process