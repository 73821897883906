import React from 'react'
import Translation from 'components/Translation'

const Costello = () => {
    return (
        <section className="costello-wrapper py-5">
            <div className="costello-content container-fluid">
                <div className="row">
                    <div className="col-xs-push-1 col-xs-10 col-sm-push-1 col-sm-5 col-lg-push-1 col-lg-4">
                        <h4><Translation translate='Costello_txt_1' textonly="true" /></h4>
                        <p className="text-right"><small>John Costello, MA, CCC-SLP<br /><Translation translate='Costello_txt_2' textonly="true" /></small></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Costello