import React from 'react'
import Translation from 'components/Translation'
import { SiteLanguages } from 'utils/Stubs/SiteLanguages'
import { connect } from 'react-redux'
import { getTranslations } from 'actions'
import { fullLocaleLabel } from 'utils/Helpers'
import { checkSiteLangSupport, fixLocaleCode } from 'utils/Stubs/SiteLanguages'
//import localeNormalizer from 'locales-normalizer'
//const localeNormalizer = require('locales-normalizer')


const dispatchAction = (dispatch, action) => dispatch(action)

class LangSelector extends React.Component {

  constructor(props) {

    super(props)

    this.changeLocale = this.changeLocale.bind(this)

    this.state = {
        localeValue: window.getCookieValue('locale')
    }

  }

  renderLangs(langs, fullLocale){
    return ( 
      langs.map(( lang, key ) => {
        let activeClass = (fullLocale === lang.label) ? ' active' : ''
            return <a href="#" key={key} onClick={ e => this.changeLocale(e)} data-locale={lang.code} className={`dropdown-item${activeClass}`}>{lang.label}</a>
        }
      )
    )
  }

  changeLocale(e){

    e.preventDefault()
    let localeCode = e.target.nodeName === 'A' ? e.target.dataset.locale : 'en-US'

    // set a cookie with new code
    window.setCookie('locale', localeCode, 365)

    // update state
    let change = { localeValue : localeCode }
    this.setState(change)

    // get translations for new code
    dispatchAction(this.props.dispatch, getTranslations())

    // process cells need to be rematched
    setTimeout(()=> window.$('.process').matchHeight(),1000)

  }

  render()  {

      const { localeValue } = this.state
      let fixCode = fixLocaleCode(localeValue)
      const useLocale = checkSiteLangSupport(fixCode) ? fixCode : 'en'
      const fullLocale = fullLocaleLabel(useLocale)
      
      return (
        <div>
          <a href="#" className="nav-link dropdown-toggle" id="accountDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <Translation translate='Language'/>: {fullLocale}
          </a>
          <div className="dropdown-menu" aria-labelledby="accountDropdown">
              {
                  this.renderLangs(SiteLanguages, fullLocale)
              }
          </div>
        </div>
      )
  }
}

const mapStateToProps = (state) => (
  {
      login: state.app.get('login')
  }
)
LangSelector = connect(mapStateToProps)(LangSelector)
export default LangSelector