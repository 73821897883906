import React from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Welcome from "components/blocks/Welcome";
import How from "components/blocks/How";
import Costello from "components/blocks/Costello";
import Banking from "components/blocks/Banking";
import Process from "components/blocks/Process";
import Additional from "components/blocks/Additional";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class About extends React.Component {
  componentDidMount() {
    window.$(".process").matchHeight();
  }

  render() {
    if (this.props.login) {
      return (
        <div className="fade-block fade-in">
          <Navbar />
          <Welcome />
          <How />
          <Costello />
          <Banking />
          <Process />
          <Additional />
          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

const mapStateToProps = (state) => ({
  login: state.app.getIn(["user"]),
});

About = connect(mapStateToProps)(About);

export default About;
