import React from 'react'
import userManager from 'utils/userManager'
import Translation from 'components/Translation'


const Started = () => {
    return (
        <section className="pb-4">
            <div className="container text-center">
                <a href={`${userManager.settings.authority}/account/register?returnUrl=${window.location.origin}/login`} className="btn btn-primary"><Translation translate='Get_Started' /></a>
            </div>
        </section>
    )
}

export default Started