import * as t from "actionTypes";

export const setAccessToken = (access_token) => {
  return {
    type: "SET_ACCESS_TOKEN",
    access_token,
  };
};

export const getTranslations = () => {
  return {
    type: t.GET_TRANSLATIONS,
  };
};

export const getAvailLangs = (availLangs) => {
  return {
    type: t.GET_AVAILABLE_LANGS,
    availLangs,
  };
};

export const setUser = (user) => {
  return {
    type: t.SET_USER,
    user,
  };
};
